import React from 'react';
import '../../App.css';
import Main from '../Main';
import Footer from '../Footer';

function Home () {
    return (
        <>
            <Main/>
            <Footer/>
        </>
    )
}

export default Home;